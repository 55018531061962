import React, { Component } from "react";
import PropTypes from "prop-types";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const styleMap = false;

class Map extends Component {
    render() {
        if (!this.props.latitude || !this.props.longitude || !this.props.link) {
            return null;
        }
        return (
            <GoogleMap
                defaultStyles={styleMap ? exampleMapStyles : undefined}
                defaultZoom={15}

                defaultCenter={{ lat: this.props.latitude, lng: this.props.longitude }}
            >
                <Marker
                    position={{ lat: this.props.latitude, lng: this.props.longitude }}
                    onClick={() => window.open(this.props.link)}
                />
            </GoogleMap>
        );
    }
}

Map.propTypes = {
    link: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
};

export default withScriptjs(withGoogleMap(Map));

const exampleMapStyles = [
    {
        featureType: "all",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#ffffff",
            },
        ],
    },
    {
        featureType: "all",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#000000",
            },
            {
                lightness: 13,
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#000000",
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#144b53",
            },
            {
                lightness: 14,
            },
            {
                weight: 1.4,
            },
        ],
    },
    {
        featureType: "landscape",
        elementType: "all",
        stylers: [
            {
                color: "#08304b",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                color: "#0c4152",
            },
            {
                lightness: 5,
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#000000",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#0b434f",
            },
            {
                lightness: 25,
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#000000",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#0b3d51",
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [
            {
                color: "#000000",
            },
        ],
    },
    {
        featureType: "transit",
        elementType: "all",
        stylers: [
            {
                color: "#146474",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "all",
        stylers: [
            {
                color: "#021019",
            },
        ],
    },
];
